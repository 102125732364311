import React from "react";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div className="policy">
        <section className="section" style={{ paddingLeft: "190px" }}>
          <div className="section__header">
            <h1 className="title" style={{ marginLeft: "-190px" }}>
              Privacy Policy
            </h1>
            <div
              className="section__subtitle"
              style={{ marginLeft: "-185px", fontSize: "14px" }}
            >
              updated 23.07.2020
            </div>
          </div>
          <div className="text-block inner">
            <h3 className="title-3">Introduction</h3>
            <p>
              Berezka DeFi Fund and its affiliates (hereinafter, “Berezka”, "the
              Company", "we", "us" or "our") are committed to protecting and
              respecting your privacy. In addition, we recognize that persons
              who use Berezka value their privacy. This Privacy Policy together
              with our Terms of Use governs our collection, processing and use
              of your Personal Information. By accessing Berezka, you are
              consenting to the information collection and use practices
              described in this Privacy Policy.
            </p>
            <p>
              This Privacy Policy describes how your personal information is
              collected, used, and shared when you visit or make a purchase from
              https://berezka.io (the “Site”). We don’t ask for your personal
              information unless we truly need it. It is Berezka's policy to
              respect your privacy regarding any information we may collect from
              you across our website
            </p>
            <p>
              When you visit the Site, we automatically collect certain
              information about your device, including information about your
              web browser, IP address, time zone, and some of the cookies that
              are installed on your device. Additionally, as you browse the
              Site, we collect information about the individual web pages or
              products that you view, what websites or search terms referred you
              to the Site, and information about how you interact with the Site.
              We refer to this automatically-collected information as “Device
              Information.”
            </p>
            <p>
              We collect Device Information using the following technologies:
            </p>
            <ul>
              <li>
                “Cookies” are data files that are placed on your device or
                computer and often include an anonymous unique identifier. For
                more information about cookies, and how to disable cookies,
                visit http://www.allaboutcookies.org.
              </li>
              <li>
                “Log files” track actions occurring on the Site, and collect
                data including your IP address, browser type, Internet service
                provider, referring/exit pages, and date/time stamps.
              </li>
              <li>
                “Web beacons,” “tags,” and “pixels” are electronic files used to
                record information about how you browse the Site.
              </li>
            </ul>
            <p>
              When we talk about “Personal Information” in this Privacy Policy,
              we are talking both about Device Information and Order
              Information.
            </p>
            <h3 className="title-3">Purpose of Privacy Policy</h3>
            <p>
              The purpose of this Privacy Policy is to set out in an accountable
              and transparent way the collection and use of information by
              Berezka
            </p>
            <h3 className="title-3">Personal Information</h3>
            <p>
              "<b>Personal Information</b>" is information which identifies you
              personally or by which your identity can reasonably be
              ascertained. This may include but is not limited to:
            </p>
            <ol>
              <li>
                Full legal name, address for service, e-mail address, phone
                number, date of birth, photographic identification, government
                issued identification and other contact details.
              </li>
              <li>Your blockchain wallet addresses.</li>
              <li>Ethereum network information related to transactions.</li>
              <li>
                We may receive Ethereum network information from you as a result
                of your interaction with Berezka.
              </li>
              <li>
                Berezka requires the highest level of browser permissions that
                could potentially lead to procurement of more Personal
                Information than enclosed in the definition of Personal
                Information.
              </li>
              <li>
                Berezka may require the use of MetaMask and may obtain
                additional information from MetaMask related to Ethereum address
                and transactions.
              </li>
              <li>
                World Wide Web related information, including but not limited to
                IP Addresses, operating system and browser type may be received
                by Berezka as a result of your interactions with Berezka
              </li>
              <li>
                Berezka uses Google Analytics for purposes of monitoring web
                traffic. Any identifying information collected via Google
                Analytics is controlled by Google.
              </li>
              <li>
                Ethereum blockchain and other public blockchains provide
                transparency into transactions and Berezka is not responsible
                for preventing or managing information broadcasted on a
                blockchain.
              </li>
            </ol>
            <h3 className="title-3">
              Security, Protection and Use of Personal Information
            </h3>
            <p>
              Berezka is committed to protecting your privacy. Internally, only
              employees with a business have access to know Personal
              Information, or whose duties reasonably require access to it are
              granted access to Personal Information.
            </p>
            <p>
              Berezka’s systems and data are constantly under review to ensure
              that you are getting the best class of service and that market
              leading security features are in place.
            </p>
            <p>
              Berezka reserves the right to retain and share certain personal
              Information in order to meet our regulatory and statutory
              requirements and further to our contractual agreements with
              partners and third parties.
            </p>
            <p>
              Personal Information and other related data may be exported
              outside of the jurisdiction in which you reside. Your Personal
              Information may be processed and stored in a foreign country or
              countries. Under those circumstances, the governments, courts, law
              enforcement or regulatory agencies of that country or those
              countries may be able to obtain access to your Personal
              Information through foreign laws. You need to be aware that the
              privacy standards of those countries may be lower than those of
              the jurisdiction in which you reside. You should note that you are
              not obliged to give your Personal Information to Berezka, but if
              you choose not to do so, we may not be able to provide our
              services, or your access to our services may be limited.
            </p>
            <h3 className="title-3">Consent</h3>
            <p>
              Consent is required for the collection of Personal Information and
              the subsequent use of disclosure of Personal Information. The form
              of consent may vary depending upon the circumstances and the type
              of Personal information obtained. Your agreement with Berezka’s
              Terms of Service constitutes your consent to the collection and
              use of Personal Information as described in this Privacy Policy.
              Berezka reserves the right to use and disclose Personal
              Information without your knowledge or consent as permitted by
              applicable law.
            </p>
            <h3 className="title-3">Disclosure of Personal Information</h3>
            <p>
              We use the Personal Information for the purposes indicated at the
              time you provide us with such information, and/or otherwise for
              the purposes set out in this Privacy Policy and/or as otherwise
              permitted by law. We may make available the Personal Information
              that you provide to us to our affiliates, agents, representatives,
              service providers and contractors for these purposes. We also
              reserve the right to disclose Personal information that Berezka
              believe, in good faith, is appropriate or necessary to enforce our
              Terms of Use, take precautions against liability or harm, to
              investigate and respond to third-party claims or allegations, to
              respond to a court order or official requests, to protect security
              or integrity of Berezka and to protect the rights, property or
              safety of Berezka, our uses or others.
            </p>
            <p>
              We may share Users' Personal Information with any financial
              dispute resolution scheme to which the Company subscribes, and
              other law enforcement bodies, regulatory agencies, courts,
              arbitration bodies and dispute resolution schemes, both in Russia
              and internationally, as may be required by law.
            </p>
            <p>
              If you request it in writing, we may share your Personal
              Information with your nominated advisers. Except where disclosure
              of your Personal Information is required by law or requested by
              you, we will generally require any third party which receives or
              has access to Personal Information to protect such Personal
              Information and to use it only to carry out the services they are
              performing for you or for us, unless otherwise required or
              permitted by law. We will ensure that any such third party is
              aware of our obligations under this Privacy Policy and we will
              take reasonable steps to ensure that contracts we enter with such
              third parties binds them to terms no less protective of any
              Personal Information disclosed to them than the obligations we
              undertake to you under this Privacy Policy or which are imposed on
              us under applicable data protection laws.
            </p>
            <p>
              In the event that Berezka is involved in a merger, acquisition,
              sale, bankruptcy, insolvency, reorganization, receivership,
              assignment or the application of laws or change of control, there
              may be a disclosure of your information to another entity related
              to such an event.
            </p>
            <h3 className="title-3">
              Access and Changing of Personal Information
            </h3>
            <p>
              You have the right to access the Personal Information we hold
              about you, and to require the correction, updating and blocking of
              inaccurate and/or incorrect data by sending an email to us. We
              will usually respond to your request within 14 days. You may also
              request the deletion or destruction of your Personal Information,
              your Account details or your Transaction details by sending an
              email to us. Berezka will act on your request only when it is not
              inconsistent with its legal and regulatory obligations and
              compliance procedures. Upon your written request, we will inform
              you of the use and general disclosure of your Personal
              Information. Depending on the nature of your request, there may be
              a minimal charge for accessing your Personal Information.
            </p>
            <h3 className="title-3">Security</h3>
            <p>
              We take security seriously, and do what we can within commercially
              acceptable means to protect your personal information from loss or
              theft, as well as unauthorized access, disclosure, copying, use or
              modification. That said, we advise that no method of electronic
              transmission or storage is 100% secure, and cannot guarantee the
              absolute security of your data.There are protective measures that
              you should take which as well include but are not limited to
              changing password regularly, not sharing your Personal Information
              with other unless you clearly understand the purpose of their
              request and you know with whom you are dealing.
            </p>
            <h3 className="title-3">Retention of Personal Information</h3>
            <p>
              We will hold your Personal Information only for as long as it is
              necessary for us to do so, having regard to the purposes described
              in this Privacy Policy and our own legal and regulatory
              requirements. In general, Personal Information relating to your
              Account for at least a period of five years after your Account is
              closed. Similarly, we usually retain information about
              Transactions on your Account for a period of five years from the
              date of the Transaction. Personal Information which is collected
              for other purposes will be discarded in accordance with our
              policies in place from time to time.
            </p>
            <h3 className="title-3">Links</h3>
            <p>
              Our website may link to external sites that are not operated by
              us. Please be aware that we have no control over the content and
              practices of these sites, and cannot assume responsibility for
              their treatment of your personal information. This privacy policy
              only covers our website and privacy practices. We do not control
              those third party sites or any of the content contained therein
              and you agree that we are in no way responsible or liable for any
              of those third party sites, including, without limitation, their
              content, policies, failures, promotions, products, services or
              actions and/or any damages, losses, failures or problems caused
              by, related to or arising from those sites. We encourage you to
              review all policies, rules, terms and regulations, including the
              privacy policies, of each site that you visit.
            </p>
            <h3 className="title-3">Changes</h3>
            <p>
              At our discretion, we may change our privacy policy from time to
              time. Your continued use of this site after any changes to this
              policy will be regarded as acceptance of our practices around
              privacy and personal information.
            </p>
            <h3 className="title-3">Contact Us</h3>
            <p>
              For more information about our privacy practices, if you have
              questions, or if you would like to make a complaint, please
              contact us by e-mail{" "}
              <a className="link-color" href="mailto:hello@berezka.io">
                hello@berezka.io
              </a>
            </p>
          </div>
        </section>
      </div>
    );
  }
}

export default PrivacyPolicy;

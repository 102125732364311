import React from "react";

class CookiePolicy extends React.Component {
  render() {
    return (
      <div className="policy">
        <section className="section" style={{ paddingLeft: "190px" }}>
          <div className="section__header">
            <h1 className="title" style={{ marginLeft: "-190px" }}>
              Cookie Policy
            </h1>
            <div
              className="section__subtitle"
              style={{ marginLeft: "-185px", fontSize: "14px" }}
            >
              updated 23.07.2020
            </div>
          </div>
          <div className="text-block inner">
            <h3 className="title-3">Introduction</h3>
            <p>
              This cookie policy explains what cookies are and how Berezka DeFi
              Fund and its affiliates (hereinafter, “Berezka”, "the Company",
              "we", "us" or "our") uses them. We encourage you to read the
              policy in full so that you can understand what information we
              collect using cookies and how that information is used. By using
              our sites, you are agreeing that we can use cookies in accordance
              with this policy. This cookies policy should be read together with
              our Privacy Policy.
            </p>
            <h3 className="title-3">What are cookies?</h3>
            <p>
              Cookies are text files, containing small amounts of information,
              which are downloaded to your browsing device, such as your
              computer, mobile device or smartphone, when you visit our website
              or use our services. Cookies can be recognised by the website that
              downloaded them — or other websites that use the same cookies.
              This helps websites know if the browsing device has visited them
              before.
            </p>
            <h3 className="title-3">What are cookies used for?</h3>
            <p>
              Cookies do lots of different jobs, like helping us understand how
              our website is being used, letting you navigate between pages
              efficiently, remembering your preferences, and generally improving
              your browsing experience. Cookies can also help ensure marketing
              you see online is more relevant to you and your interests.
            </p>
            <h3 className="title-3">What are the types of cookies?</h3>
            <b>Functional Cookies</b>
            <br />
            <p>
              Functional cookies are essential to provide our services. They are
              used to remember your preferences on our website and to provide
              enhanced and personalised experience. The information collected by
              these cookies is usually anonymised, so we cannot identify you
              personally. Functional cookies do not track your internet usage or
              gather information which could be used for selling advertising,
              but they do help with providing advertising content. These cookies
              are usually session cookies which will expire when you close your
              browsing session.
            </p>
            <b>Essential or ‘Strictly Necessary’ Cookies</b>
            <br />
            <p>
              These cookies are essential to provide our services. Without these
              cookies, parts of our website will not function. These cookies do
              not track where you have been on the internet and do remember
              preferences beyond your current visit and do not gather
              information about you that could be used for marketing purposes.
              These cookies are usually session cookies which will expire when
              you close your browsing session.
            </p>
            <b> Analytical Performance Cookies</b>
            <br />
            <p>
              Analytical performance cookies are used to monitor the performance
              of our website and services, for example, to determine the number
              of pageviews and the number of unique users a website has. Web
              analytics services may be designed and operated by third parties.
              The information provided by these cookies allows us to analyse
              patterns of user behaviour and we use that information to enhance
              user experience or identify areas of the website which may require
              maintenance. The information is anonymous, cannot be used to
              identify you, does not contain personal information and is only
              used for statistical purposes
            </p>
            <b>Advertising Cookies</b>
            <br />
            <p>
              These cookies remember that you have visited a website and use
              that information to provide you with content or advertising which
              is tailored to your interests. They are also used to limit the
              number of times you see an advertisement as well as help measure
              the effectiveness of the advertising campaign. The information
              collected by these cookies may be shared with trusted third party
              partners such as advertisers.
            </p>
            <p>
              We may update this Cookie Policy from time to time for
              operational, legal or regulatory reasons. You can revisit this
              page if you wish to keep yourself informed.
            </p>
            <h3 className="title-3">
              How long will cookies stay on my browsing Device?
            </h3>
            <p>
              The length of time a cookie will stay on your browsing device
              depends on whether it is a “persistent” or “session” cookie.
              Session cookies will only stay on your device until you stop
              browsing. Persistent cookies stay on your browsing device after
              you have finished browsing until they expire or are deleted.
            </p>
            <h3 className="title-3">First and third-party cookies</h3>
            <p>
              “First party cookies” are cookies that belong to us and that we
              place on your device. “Third-party cookies” are cookies that
              another party places on your browsing device when you visit our
              site. Third parties setting cookies from our website will be
              providing a service to us or a function of the site, but we do not
              always control how third-party cookies are used. You should check
              the third party’s website for more information about how they use
              cookies.
            </p>
            <h3 className="title-3">
              How to manage cookies from this website?
            </h3>
            <p>
              You can usually use the browser that you are viewing this website
              through to enable, disable or delete cookies. To do this, follow
              the instructions provided by your browser (usually located within
              the “Help”, “Tools” or “Edit” settings). Please note that if you
              set your browser to disable cookies, you may not be able to access
              secure areas of the website and other parts of the website may
              also not work properly.
            </p>
            <p>
              You can find out more information about how to change your browser
              cookie settings at www.allaboutcookies.org.
            </p>
            <p>
              Some third parties may use Advertising Cookies to help gather
              information about your browsing activity so that they can deliver
              website advertising to you that is relevant to your interests. The
              advertising industries in EU have developed schemes to help you
              opt-out of receiving cookies used for these purposes. You can find
              out more about the EU scheme from www.youronlinechoices.eu .
            </p>
            <h3 className="title-3">Changes</h3>
            <p>
              Information about the cookies used by us may be updated from time
              to time, so please check back on a regular basis for any changes.
              The last modification date of this document is shown at the
              beginning of this document.
            </p>
            <h3 className="title-3">Contact Us</h3>
            <p>
              For more information about our privacy practices, if you have
              questions, or if you would like to make a complaint, please
              contact us by e-mail{" "}
              <a className="link-color" href="mailto:hello@berezka.io">
                hello@berezka.io
              </a>
            </p>
          </div>
        </section>
      </div>
    );
  }
}

export default CookiePolicy;

import React from "react";

function HeaderBlocked() {
  return (
    <div className="balance">
      <div className="balance__amount"></div>
    </div>
  );
}

export default HeaderBlocked;

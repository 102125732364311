export const blacklist = [
  "AF",
  "AL",
  "BS",
  "BB",
  "BW",
  "KH",
  "CU",
  "GH",
  "IS",
  "IR",
  "IQ",
  "JM",
  "MU",
  "MN",
  "MM",
  "NI",
  "KP",
  "PK",
  "PA",
  "SO",
  "SY",
  "SD",
  "SS",
  "TT",
  "UG",
  "VU",
  "YE",
  "ZW",
  "US",
];
